<template>
    <div class="gray-bg" v-htmltit data-title="订单列表">
        <!-- <my-header :title="title"></my-header> -->
        <div class="order my-main">
            <go-cart-btn></go-cart-btn>
            <van-tabs v-model="tabActive" @click="handleChangeTab" swipeable sticky color="#FF976A" title-active-color="#FF976A">
                <van-tab v-for="item in tabList" :key="item.name" :name="item.name" :title="item.title" >
                    <!-- <skeleton v-if="!loadingSkeleton"></skeleton> -->
                    <!-- <order-list v-if="loading" :type="orderType"></order-list> -->
                    <div class="order-list">
                        <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
                            <van-list
                                class="order-list"
                                v-model="loading"
                                :finished="finished"
                                :immediate-check="false"
                                finished-text="已经到底了"
                                @load="onLoad">
                                <div class="order-item" v-for="item in orderlist" :key="item.id">
                                    <div class="order-title">
                                        <van-icon style="font-size:1rem" name="column" />
                                        <div class="order-num">{{item.orderno}}</div>
                                        <div class="order-total-count">{{item.orderSkuTotalquantity}}类商品</div>
                                    </div>
                                    <div v-if="item.skulist.length == 1" class="order-con"  @click="handleClickGoDetail(item)" style="align-items: flex-start;">
                                        <template v-for="childItem in item.skulist">
                                            <div class="order-img"  :key="childItem.id">
                                                <van-image fit="contain"
                                                    class="img-box"
                                                    :src="getImgSizeJpg(childItem.img,4,3.6)">
                                                    <template v-slot:error>
                                                        <img style="width:100%;height:3.6rem" :src="defaultImg" />
                                                    </template>
                                                    <template v-slot:loading>
                                                        <img style="width:100%;height:3.6rem" :src="defaultImg" />
                                                    </template>
                                                </van-image>
                                                <div class="good-count">{{childItem.quantity}}</div>
                                            </div>
                                            <div class="good-name-box" :key="childItem.id">
                                                <div class="good-name">{{childItem.name}}</div>
                                                <div class="good-sku"  v-if="childItem.standard">{{childItem.standard}}</div>
                                            </div>
                                        </template>
                                    </div>
                                    <div v-if="item.skulist.length == 2" class="order-con" @click="handleClickGoDetail(item)">
                                        <div class="order-img" v-for="childItem in item.skulist" :key="childItem.id">
                                            <van-image fit="contain"
                                                class="img-box"
                                                :src="getImgSizeJpg(childItem.img,4,3.6)">
                                                <template v-slot:error>
                                                    <img style="width:100%;height:3.6rem" :src="defaultImg" />
                                                </template>
                                                <template v-slot:loading>
                                                    <img style="width:100%;height:3.6rem" :src="defaultImg" />
                                                </template>
                                            </van-image>
                                            <div class="good-count"> {{childItem.quantity}} </div>
                                        </div>
                                    </div>
                                    <div v-if="item.skulist.length >= 3" class="order-con" @click="handleClickGoDetail(item)">
                                        <template v-for="(childItem,index) in item.skulist">
                                        <div class="order-img"  v-if=" index < 3"  :key="childItem.id">
                                            <template v-if=" index < 3">
                                                <van-image fit="contain"
                                                    class="img-box"
                                                    :src="getImgSizeJpg(childItem.img,4,3.6)">
                                                    <template v-slot:error>
                                                        <img style="width:100%;height:3.6rem" :src="defaultImg" />
                                                    </template>
                                                    <template v-slot:loading>
                                                        <img style="width:100%;height:3.6rem" :src="defaultImg" />
                                                    </template>
                                                </van-image>
                                                <div class="good-count">{{childItem.quantity}}</div>
                                            </template>
                                        </div>
                                        </template>
                                        <div class="more">
                                            <div>查看</div>
                                            <div>更多</div>
                                            <van-icon style="font-size: 0.88rem;" class="more-icon" name="upgrade" />
                                        </div>
                                    </div>
                                    <div class="order-footer">
                                        <div class="time">下单时间:{{item.orderTime}}</div>
                                        <div class="price-box">总价：<span class="price">{{Number(item.orderPayMoney).toFixed(2)}}</span></div>
                                    </div>
                                    <div class="order-opt">
                                        <div class="btn-list">
                                            <div v-if="item.payStatus == 0 && item.documentStatus == 0" class="btn cancel" @click="handleClickCancel(item)">取消订单</div>
                                            <!-- <div class="btn ok" @click="handleClickAngin">再来一单</div> -->
                                            <!-- <div v-if="tabActive == 3" class="btn shou" @click="handleClickConfirm">确认收货</div> -->
                                            <div v-if="item.payStatus == 0" class="btn shou" @click="handleClickPay(item)">去支付</div>
                                        </div>
                                    </div>
                                </div>
                            </van-list>
                        </van-pull-refresh>
                    </div>
                </van-tab>
            </van-tabs>
        </div>
        <my-footer></my-footer>
    </div>
</template>
<script>
import './index.less'
// import myHeader from "@/components/Header"
import goCartBtn from "@/view/Public/goCartBtn"
import myFooter from "@/components/Footer"
// import orderList from "./component/orderList"
import skeleton from "./component/skeleton"

import { getOrderList ,orderCancel ,orderPayAgain } from "@/apis/api"
import { formatTime } from "@/libs/utils"
import defaultImg from "@/assets/image/default.png"
import { wxConfig} from  "@/libs/utils"
import bshop_global from "@/libs/global"
import { mapActions } from 'vuex'
export default {
    name:"order",
    components:{ skeleton, myFooter ,goCartBtn},
    data(){
        return{
            isFirstEnter: true,
            tabList:[
                { name:1, title:"待支付" },
                { name:2, title:"待处理" },
                { name:3, title:"已处理" },
                // { name:4, title:"已完成" },
            ],
            tabActive:1,
            defaultImg:defaultImg,
            page:1,
            pageSize:10,
            isLoading:false,//下拉刷新loading
            loading: false,//上拉加载列表的数据
            finished: false,//上拉加载列表的数据
            orderlist:[]
        }
    },
    // beforeRouteEnter(to, from, next){
    //     if (from.name === 'payment-success'){
    //         next(vm => {
    //             vm.tabActive = 2;
    //         })
    //     }else{
    //         next()
    //     }
    // },
    mounted(){
        this.isFirstEnter = true
    },
    activated(){
        if(this.$route.params && this.$route.params.tabActive){
            this.tabActive = this.$route.params.tabActive
        }
        if(!this.isFirstEnter || this.$route.meta.isfresh){
            let keepAliveArr = ['order-confirm','order-detail','cart','payment-error','payment-success']
            const beforeRouterName = this.beforeRouter.name;
            if ((beforeRouterName &&  !keepAliveArr.includes(beforeRouterName)) ||(this.$route.meta.isfresh)) {
                // 手动刷新页面
                this.orderlist = []
                this.onRefresh()
            }
        }else{
            this.onLoad()
        }
        this.isFirstEnter = false
    },
    methods:{
        ...mapActions(['getNoPayCount']),
        handleChangeTab(){
            this.page = 1
            this.finished = false;
            this.loading = true;
            this.orderlist = [];
            this.onLoad();
        },
        onRefresh() {
            this.page = 1
            this.finished = false;
            this.loading = true;
            this.orderlist = []
            this.onLoad()
        },
        onLoad() {
            let data = {
                orderStatus:this.tabActive,
                page:this.page,
                pageSize:10
            }
            getOrderList(data).then(res=>{
                if(res.code == 0){
                    if (this.isLoading) {
                        this.orderlist = [];
                        this.isLoading = false;
                    }
                    this.page++;
                    let newList = res.res.goods;
                    newList.map(item=>{
                        item.orderTime = formatTime(item.orderTime,'Y-M-D h:m:s')
                    })
                    if(newList.length < this.pageSize){
                        this.finished = true; 
                    }
                    this.orderlist = this.orderlist.concat(newList);
                    // 加载状态结束
                    this.loading = false;
                    // 数据全部加载完成
                    if(this.orderlist.length >= res.res.count){
                        this.finished = true;
                    }
                }else{
                     this.$toast(res.msg)
                }
            })
        },
        //去详情页面
        handleClickGoDetail(item){
            this.$router.push({ name:'order-detail',query:{ "id":item.id } })
        },
        //取消订单
        handleClickCancel(item){
            if(item.place_order_type === '2') {
                this.$toast('请联系平台客服，取消代下订单')
                return false
            }
            this.$dialog.confirm({
                width:'88%',
                title: '提示',
                message: '确认取消吗？'
            }).then(() => {
                let params = {
                    order_id:item.id,
                    customer_master_id:localStorage.getItem('customermasterId')
                }
                orderCancel(params).then(res=>{
                    if(res.code == 0){
                        this.page = 1
                        this.finished = false;
                        this.loading = true;
                        this.orderlist = [];
                        this.onLoad();
                        this.$toast("取消成功") 
                        this.getNoPayCount()
                    }else{
                        this.$toast(res.msg)
                    }
                })
            }).catch(()=>{})
        },
        //再来一单
        handleClickAngin(){
            this.$toast("暂未开放")
                return;
            this.$toast("已加入购物车")
        },
        //确认收货
        handleClickConfirm(){
            this.$toast("暂未开放")
                return;
            this.$toast("确认收货")
        },
        //去支付
        handleClickPay(item){
            this.$router.push({ name:'order-detail',query:{ "id":item.id } })
            // let toast = this.$toast.loading({
            //     message: '加载中...',
            //     forbidClick: true,
            //     duration: 0,
            //     loadingType: 'spinner',
            // });
            // let params = {
            //     order_id:item.id
            // }
            // orderPayAgain(params).then(res=>{
            //     if(res.code == 0){
            //         if(item.payType == 1){
            //             this.$router.replace({ name:"payment-success" })
            //         }else if(item.payType == 2){
            //             wxConfig(res.res.wxJsApiParam);
            //             //将返回的信息放在缓存中 以便重新支付时获取
            //             localStorage.setItem("againRes",JSON.stringify(res.res.wxJsApiParam))
            //         }else if(item.payType == 3){
            //         }else if(item.payType == 4){
            //             window.location.href =  bshop_global.bShopUrl + "/#/payinfo?code=" + res.res.orderId+"&customerId=" + localStorage.getItem('customerId') +"&sysOfficeId="+ localStorage.getItem('sysOfficeId');
            //         } else if(item.payType == 5){
            //             this.$router.replace({ name:"payment-success"})
            //         }
            //     }else{
            //         this.$toast(res.msg)
            //     }
            //     toast.clear();
            // })
        }
    }
}
</script>