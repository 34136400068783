<template>
    <div>
        <div class="skeleton-list">
            <div class="skeleton-item" v-for="(item,index) in data" :key="index">
                <div class="skeleton-item-order-title">
                    <div style="width:5%"> <van-skeleton title  title-width="100%" /></div>
                    <div style="width:50%;margin-left: -4rem;"> <van-skeleton title  title-width="100%" /></div>
                    <div style="width:15%"> <van-skeleton title  title-width="100%" /></div>
                </div>
                <div class="skeleton-item-order-con">
                    <div class="skeleton-item-order-con-item">
                        <van-skeleton title  title-width="100%" />
                        <van-skeleton title  title-width="100%" />
                        <van-skeleton title  title-width="100%" />
                        <van-skeleton title  title-width="100%" />
                    </div>
                    <div class="skeleton-item-order-con-item">
                        <van-skeleton title  title-width="100%" />
                        <van-skeleton title  title-width="100%" />
                        <van-skeleton title  title-width="100%" />
                        <van-skeleton title  title-width="100%" />
                    </div>
                    <div class="skeleton-item-order-con-item">
                        <van-skeleton title  title-width="100%" />
                        <van-skeleton title  title-width="100%" />
                        <van-skeleton title  title-width="100%" />
                        <van-skeleton title  title-width="100%" />
                    </div>
                    <div class="skeleton-item-order-con-item">
                        <van-skeleton title  title-width="100%" />
                        <van-skeleton title  title-width="100%" />
                        <van-skeleton title  title-width="100%" />
                        <van-skeleton title  title-width="100%" />
                    </div>
                </div>
                <div class="skeleton-item-order-footer">
                    <div style="width:50%">
                        <van-skeleton :row="1" row-width="100%" />
                    </div>
                    <div style="width:25%">
                        <van-skeleton :row="1" row-width="100%" />
                    </div>
                </div>
                <div class="skeleton-item-order-opt">
                    <div style="width:25%;margin-left:0.5rem">
                        <van-skeleton :row="1" row-width="100%" />
                        <van-skeleton :row="1" row-width="100%" />
                    </div>
                    <div style="width:25%;margin-left:0.5rem">
                        <van-skeleton :row="1" row-width="100%" />
                        <van-skeleton :row="1" row-width="100%" />
                    </div>
                    <div style="width:25%;margin-left:0.5rem">
                        <van-skeleton :row="1" row-width="100%" />
                        <van-skeleton :row="1" row-width="100%" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import "./index.less"
export default {
    name:"skeleton",
    data(){
        return{
            data:[0,1,2]
        }
    }
}
</script>